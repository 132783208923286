import React, { useEffect, useState, useTransition } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import IconPlus from '@/components/Icon/IconPlus';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';

import { useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { UserModel } from '@/app/Auth/redux/user';
import formatDateToLongForm from '@/utils/DateFormattter';

import Filter from '@/components/filters/Filter';
import { useGetofficeQuery } from '..';
import { Office } from '../API';
import { UpdateOffice } from './UpdateOffice';
import { DeleteOffice } from './DeleteOffice';
import { AddOffice } from './AddOffice';
import { More } from './More';

const Offices = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching } = useGetofficeQuery(
        { pageNumber, pageSize, from, to, search, status },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const resetFilters = () => {
        setRole('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [sortedData, setSortedData] = useState<any>([]);
    const { offices } = useAppSelector((state: any) => state.officeState);

    useEffect(() => {
        if (offices) {
            setSortedData(offices?.list);
        }
    }, [offices]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<Office>[] = [
        {
            title: 'Names',
            accessor: 'name',
            render: row => <p className="capitalize">{row.name}</p>,
        },
        {
            title: 'Client Name',
            accessor: 'client.name',
            render: row => <p>{row.client.name}</p>,
        },
        {
            title: 'Client Email',
            accessor: 'client.email',
            render: row => <p>{row.client.email}</p>,
        },

        {
            title: 'Branch Name',
            accessor: 'branch.name',
            render: row => <p>{row.branch.name}</p>,
        },
        {
            title: 'Branch Location',
            accessor: 'branch.location',
            render: row => <p>{row.branch.location}</p>,
        },
        {
            title: 'Branch Email',
            accessor: 'branch.email',
            render: row => <p>{row.branch.email}</p>,
        },
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    <span
                        className={`badge ${
                            row.status === 'active' ? 'bg-success' : 'bg-danger'
                        }`}
                    >
                        {row.status === 'active' ? 'Active' : 'Inactive'}
                    </span>
                </p>
            ),
        },

        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <UpdateOffice data={row} handleRefetch={handleRefetch}  />

                    <More data={row} />
                    <DeleteOffice user={row} handleRefetch={handleRefetch} />
                </div>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Offices'));
    });

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

    return (
        <div className="   p-3 ">
            <div className="flex flex-row justify-between items-center my-2 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/admin"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>Offices</span>
                    </li>
                </ul>
                <AddOffice handleRefetch={handleRefetch} />
            </div>

            <ExportDataTable
                columns={columns}
                tableName="Offices"
                data={sortedData ?? []}
                total={offices?.total ?? 0}
                currentPage={offices?.currentPage ?? 0}
                nextPage={offices?.nextPage ?? 0}
                previousPage={offices?.previousPage ?? 0}
                lastPage={offices?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter Offices"
                        children={
                            <>
                                <div className="flex flex-col  ">
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">From :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="toDate" className="">
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            id="toDate"
                                            className="form-input "
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default Offices;
